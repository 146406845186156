import React, { FC } from 'react';
import { Text } from '@components/Text/Text.component';
import { MeteoCityAutocomplete } from '@meteo/components/MeteoCityAutocomplete/MeteoCityAutocomplete.component';
import { City } from '@meteo/types';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import classnames from 'classnames';
import styles from './MeteoHomeSummaryNoCity.module.scss';

export type MeteoHomeSummaryNoCityProps = {
  setCity?: (city: City) => void;
};

const MeteoHomeSummaryNoCity: FC<MeteoHomeSummaryNoCityProps> = ({ setCity }) => {
  const isMobileOrTablet = useIsBreakpointDown(Breakpoint.l);
  return (
    <header>
      <Text
        className={classnames(styles.meteoHomeSummaryNoSelectedCityLabel, 'plc-mb-m')}
        i18nKey="meteo.no-city-selected-title"
        tag="div"
        variant={isMobileOrTablet ? 'h3' : 'h2'}
      />
      <Text
        className={classnames(styles.meteoHomeSummaryNoSelectedCityLabel, 'plc-mb-m')}
        i18nKey="meteo.no-city-selected-subtitle"
        tag="div"
        variant="small"
      />
      <MeteoCityAutocomplete
        inputId="meteo-city-autocomplete-input-no-city"
        isLeftIconStyle
        // eslint-disable-next-line react/jsx-no-bind
        onSelect={(c) => (c && setCity ? setCity(c) : undefined)}
        withConnectSuggestion
      />
    </header>
  );
};

export { MeteoHomeSummaryNoCity };
